<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="jumbotron">
          <h1>Contactformulier</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-sm text-white">
          <form @submit.prevent="handleSubmit" v-if="showForm">
            <div class="row">
              <!-- Form left -->
              <div class="col-sm">
                <div class="form-group">
                  <label for="nameBusiness" class="text-white"
                    >Naam Onderneming</label
                  >
                  <input
                    type="input"
                    class="form-control"
                    id="nameBusiness"
                    v-model="nameBusiness"
                  />
                </div>
                <div class="form-group">
                  <label for="namePerson" class="text-white"
                    >Naam contactpersoon</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="namePerson"
                    v-model="namePerson"
                  />
                </div>

                <div class="form-group">
                  <label for="street" class="text-white">Straatnaam</label>
                  <input
                    type="text"
                    class="form-control"
                    id="street"
                    v-model="street"
                  />
                </div>

                <div class="form-group">
                  <label for="postalCode" class="text-white">Postcode</label>
                  <input
                    type="text"
                    class="form-control"
                    id="postalCode"
                    v-model="postalCode"
                  />
                </div>

                <div class="form-group">
                  <label for="phone" class="text-white">Telefoonnummer</label>
                  <input
                    type="tel"
                    class="form-control"
                    id="phone"
                    v-model="phone"
                  />
                </div>
                <hr />

                <div class="form-group">
                  <label for="brand" class="text-white">Merk auto</label>
                  <input
                    type="text"
                    class="form-control"
                    id="brand"
                    v-model="brand"
                  />
                </div>

                <div class="form-group">
                  <label for="year" class="text-white">Bouwjaar</label>
                  <input
                    type="number"
                    class="form-control"
                    id="year"
                    v-model="year"
                  />
                </div>

                <div class="form-group">
                  <label for="damagefree" class="text-white"
                    >Schadevrije jaren</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="damagefree"
                    v-model="damagefree"
                  />
                </div>

                <div class="form-group">
                  <label for="money" class="text-white">Huidige premie</label>
                  <input
                    type="number"
                    class="form-control"
                    id="money"
                    v-model="money"
                  />
                </div>

                <input
                  type="text"
                  class="form-control screwYou"
                  id="screwYou"
                  v-model="screwYou"
                />

                <div class="form-group">
                  <label class="text-white">Werktijden</label>
                  <div>
                    <label for="from" class="text-white">Van</label>
                    <input
                      type="time"
                      class="form-control"
                      id="from"
                      v-model="from"
                    />
                    <small>
                      <label for="until" class="text-white">Top</label>
                    </small>
                    <input
                      type="time"
                      class="form-control"
                      id="until"
                      v-model="until"
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  class="btn btn-primary d-none d-sm-block"
                  value="Submit"
                >
                  Verstuur
                </button>
              </div>

              <!-- Form left end -->

              <!-- Form right -->
              <div class="col-sm">
                <div class="form-group">
                  <label for="tto" class="text-white">Naam TTO</label>
                  <input
                    type="text"
                    class="form-control"
                    id="tto"
                    v-model="tto"
                  />
                </div>
                <div class="form-group">
                  <label for="email" class="text-white">Email adres</label>
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    v-model="email"
                  />
                </div>

                <div class="form-group">
                  <label for="houseNo" class="text-white">Huisnummer</label>
                  <input
                    type="number"
                    class="form-control"
                    id="houseNo"
                    v-model="houseNo"
                  />
                </div>
                <div class="form-group">
                  <label for="place" class="text-white">Plaatsnaam</label>
                  <input
                    type="text"
                    class="form-control"
                    id="place"
                    v-model="place"
                  />
                </div>
                <div class="form-group">
                  <label for="mobile" class="text-white"
                    >Telefoonnummer mobiel</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="mobile"
                    v-model="mobile"
                  />
                </div>
                <hr />
                <div class="form-group">
                  <label for="plateNo" class="text-white">Kenteken</label>
                  <input
                    type="text"
                    class="form-control"
                    id="plateNo"
                    v-model="plateNo"
                  />
                </div>
                <div class="form-group">
                  <label for="code" class="text-white">Meldcode</label>
                  <input
                    type="number"
                    class="form-control"
                    id="code"
                    v-model="code"
                  />
                </div>
                <div class="form-group">
                  <label for="worth" class="text-white"
                    >Verzekeringswaarde</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="worth"
                    v-model="worth"
                  />
                </div>

                <button
                  type="submit"
                  class="btn btn-primary d-block d-sm-none"
                  value="Submit"
                >
                  Verstuur
                </button>
              </div>
              <!-- Form right end -->
            </div>
          </form>
          <div v-else>
            <p class="text-white">Bedankt voor uw aanvraag!</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
// @ is an alias to /src

export default {
  name: 'Formulier',
  props: {
    showform: Boolean,
  },

  data: function () {
    return {
      showForm: true,
      nameBusiness: null,
      tto: null,
      namePerson: null,
      email: null,
      street: null,
      houseNo: null,
      postalCode: null,
      place: null,
      phone: null,
      mobile: null,
      brand: null,
      plateNo: null,
      year: null,
      code: null,
      damagefree: null,
      worth: null,
      money: null,
      screwYou: null,
      from: null,
      until: null,
    }
  },
  methods: {
    handleSubmit() {
      if (!this.screwYou) {
        const email = 'https://pietserver.nl:4000/email'

        let payload = {
          businessName: this.nameBusiness,
          ttoName: this.tto,
          contactName: this.namePerson,
          emailAddress: this.email,
          streetName: this.street,
          houseNumber: this.houseNo,
          postalCode: this.postalCode,
          place: this.place,
          phoneNo: this.phone,
          cellphoneNo: this.mobile,
          carBrand: this.brand,
          plateNo: this.plateNo,
          carDob: this.year,
          carCode: this.code,
          damageFreeYears: this.damagefree,
          insuranceAmount: this.worth,
          currentAmount: this.money,
          from: this.from,
          until: this.until,
          cebs: true,
        }

        this.axios({
          method: 'post',
          url: email,
          data: payload,
        })
          .then((response) => {
            // this.showForm = false
            alert('Contactformulier verzonden!')

            this.handleFeedback()
          })
          .catch(function (error) {
            // handle error
            alert('Contactformulier niet verzonden')
          })
          .finally(function () {
            // always executed
          })
      } else {
        alert('Screw You')
      }
    },
    handleFeedback() {
      if (!this.screwYou) {
        const email = 'https://pietserver.nl:4000/feedback'

        let payload = {
          businessName: this.nameBusiness,
          ttoName: this.tto,
          contactName: this.namePerson,
          emailAddress: this.email,
          streetName: this.street,
          houseNumber: this.houseNo,
          postalCode: this.postalCode,
          place: this.place,
          phoneNo: this.phone,
          cellphoneNo: this.mobile,
          carBrand: this.brand,
          plateNo: this.plateNo,
          carDob: this.year,
          carCode: this.code,
          damageFreeYears: this.damagefree,
          insuranceAmount: this.worth,
          currentAmount: this.money,
          from: this.from,
          until: this.until,
          cebs: true,
        }

        this.axios({
          method: 'post',
          url: email,
          data: payload,
        })
          .then(function (response) {
            // handle success
          })
          .catch(function (error) {
            // handle error
          })
          .finally(function () {
            // always executed
          })
      } else {
        alert('Screw You')
      }
    },
  },
}
</script>
<style lang="scss">
.vue-map-container {
  width: 100%;
  height: 400px;
}

.screwYou {
  visibility: hidden;
}
</style>
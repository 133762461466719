<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="jumbotron">
          <h1>Over</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-sm middle">
          <img class="image styled-image" src="img/1.jpg" alt="img/maybach" />
        </div>
        <div class="col-sm middle">
          <p class="text-white">
            CEBS is de eerste coöperatie, met als doel het in eigen beheer regelen van cascoschades m.b.t.
            personenvervoer.
            <br />
            <br />CEBS, een belangenorganistie, is opgericht met doelstelling om voor de aangesloten leden een fonds
            te beheren, waaruit casco evenementen worden uitgekeerd en verhaald op een mogelijke
            tegenpartij.
            <br />Het idee is voortgekomen uit de huidige verzekeringsmelaise, waar steeds meer verzekeraars zich
            terugtrekken uit o.a. de branche personenvervoer. Middels een sterk collectief, o.a. nette leden
            (ondernemers/chauffeurs) en sociale controle, zullen wij gezamenlijk bewijzen dat het
            personenvervoer een prachtige branche is, die altijd in ontwikkeling staat. In tegenstelling tot de
            terughoudende houding van verzekeraars én de vele negatieve berichtgeving rond deze branche.
          </p>
        </div>
      </div>

      <hr class="horizontal-rule" />

      <div class="row">
        <div class="col-sm order-sm-2 middle">
          <img class="image styled-image" src="img/2.jpg" alt="img/bmw" />
        </div>
        <div class="col-sm order-sm-1 middle">
          <p class="text-white">
            CEBS staat onder leiding van het bestuur en een commissie. De CEBS-commissie bestaat uit diverse
            ondernemers uit de branche personenvervoer. De CEBS-commissie buigt zich met name over
            toetreding van nieuwe leden en houdt zich bezig met de ontwikkeling en innovatie van CEBS.
            <br />
            <br />Uiteraard zijn er er strenge voorwaarden aan de toelating verbonden. Met name de aard van de
            werkzaamheden en het risicogebied zijn mede bepalend. Aanmelding van nieuwe leden kan
            uitsluitend via introductie van een al bestaand lid en moet daarbij al lang werkzaam zijn in de
            branche. Na de introducatie zal de CEBS-commissie beslissen over de toelating.
            Door deze strenge toelatingseisen wordt hiermee een exclusief collectief opgebouwd.
          </p>
        </div>
      </div>

      <hr class="horizontal-rule" />

      <div class="row">
        <div class="col-sm middle">
          <img class="image styled-image" src="img/3.jpg" alt="img/maybach" />
        </div>
        <div class="col-sm middle">
          <p class="text-white">
            CEBS doet dit in samenwerking met TIS Risk Managers B.V. en Schadehulp B.V.
            Eerst genoemde doet de administratie- en financiële afwikkeling en zal het casco gedeelte in één
            pakket voegen, samen met een WAM- en inzittendendekking.
            Schadehulp zal schades behandelen, in de meest breede vorm van het woord. Tevens zal dit
            compleet transparant zijn, middels inzage in onlinedossier; waarin onder andere communcatie
            tussen tegenpartijen, expertise, uitkeringen en statussen te zien zijn.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Over',
}
</script>

<style lang="scss">
.jumbotron {
  width: 100%;
}
.image {
  max-width: 100%;
}
</style>
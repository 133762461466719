<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="jumbotron">
          <h1>Downloads</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <ul class="list-group">
            <li class="list-group-item">
              <a
                target="_blank"
                class="text-dark"
                href="./files/Digitaal_Schade_Aangifte_Formulier.pdf"
              >Digitaal schade aangifte formulier</a>
            </li>
            <li class="list-group-item">
              <a
                target="_blank"
                class="text-dark"
                href="./files/Offerteaanvraagformulier CEBS.pdf"
              >Offerte aanvraagformulier</a>
            </li>
            <li class="list-group-item">
              <a
                target="_blank"
                class="text-dark"
                href="./files/getuigenverklaring-ongeval.pdf"
              >Getuigenverklaring ongeval</a>
            </li>
            <li class="list-group-item">
              <a
                target="_blank"
                class="text-dark"
                href="./files/formulierenset-waarborgfonds.pdf"
              >Formulierenset Waarborgfonds</a>
            </li>
            <li class="list-group-item">
              <a
                target="_blank"
                class="text-dark"
                href="./files/Ledenovereenkomst.pdf"
              >Ledenovereenkomst</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Downloads',
  methods: {
    track() {
      this.ga.page('/')
    },
  },
}
</script>

<style lang="scss">
.vue-map-container {
  width: 100%;
  height: 400px;
}

.list-group-item {
  &:before {
    content: '';
    width: 32px;
    height: 32px;
    background-image: url('/img/icons/cloud-computing.svg');
    display: inline-block;
    margin-right: 18px;
    vertical-align: middle;
  }
}
</style>
<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="jumbotron">
          <h1>Bestuur</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <!--<div class="col-sm">
          <img class="image" src="img/foto.png" alt="Foto van je pa en jij" />
        </div>-->
        <div class="col-sm">
          <p class="text-white">
            Het bestuur bestaat uit René en Kenny Enthoven, gezamelijk meer dan
            30 jaar ervaring als assurantietussenpersoon, welke zich onder
            andere altijd heeft ingezet voor de personenvervoer branche.
            Gezamenlijk zijn ze met dit idee gekomen en zijn vervolgens de
            mogelijkheden gaan uitzoeken. Samen met de commissie is het
            uiteindelijk van de grond gekomen!
          </p>
        </div>
      </div>

      <hr class="horizontal-rule" />

      <div class="row">
        <div class="col-sm order-sm-1">
          <h4 class="text-white">CEBS Commissie</h4>

          <p class="text-white">
            De commissie bestaat uit ondernemers actief in deze branche:
          </p>
          <ul class="text-white list-unstyled">
            <li>A.Ersu</li>
            <li>A.W. Noermohamed</li>
            <li>G. Sahin</li>
            <li>D.M. Kuipers</li>
            <li>I.W. Amier</li>
          </ul>
        </div>
        <div class="col-sm">
          <h4 class="text-white">Contact</h4>
          <ul class="text-white list-unstyled">
            <li>Coöperatie CEBS U.A.</li>
            <li>Postbus 12887</li>
            <li>1100 AW Amsterdam</li>
            <li>
              <a class="text-white" href="mailto:ledenadministratie@cebs.nl"
                >ledenadministratie@cebs.nl</a
              >
            </li>
            <li>
              <a class="text-white" href="callto:+3126368191">020-6368191</a>
            </li>
          </ul>
        </div>
      </div>

      <hr class="horizontal-rule" />

      <div class="row">
        <div class="col-sm">
          <h4 class="text-white">Onze partners</h4>
        </div>
      </div>

      <div class="row">
        <div class="col-sm">
          <img class="image" src="img/partners/tis.png" alt="TisRM" />
        </div>
        <div class="col-sm">
          <img
            class="image"
            src="img/partners/schadehulp.png"
            alt="Schadehulp"
          />
        </div>
        <div class="col-sm">
          <img class="image" src="img/partners/vereende.png" alt="Vereende" />
        </div>
        <div class="col-sm">
          <img class="image" src="img/partners/hienfeld.jpg" alt="Hienfield" />
        </div>
        <div class="col-sm">
          <img class="image" src="img/partners/vcn.jpg" alt="VCN" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Bestuur',
}
</script>

<style lang="scss">
.image {
  max-width: 100%;
  margin: 1em auto;
  width: 100%;
}
</style>
<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="jumbotron">
          <h1>Contact</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-sm text-white">
          <h5 class="text-uppercase">Contactgegevens</h5>
          <ul class="list-unstyled">
            <li>Coöperatie CEBS U.A.</li>
            <li>Muiderstraat 1</li>
            <li>1011 PZ Amsterdam</li>
            <br />
            <li>Postbus 12887</li>
            <li>1100 AW Amsterdam</li>
            <br />
            <li>
              <a class="text-white" href="mailto:ledenadministratie@cebs.nl"
                >ledenadministratie@cebs.nl</a
              >
            </li>
            <li>
              <a class="text-white" href="callto:+3126368191">020-6368191</a>
            </li>
          </ul>
        </div>
        <div class="col-sm middle">
          <img class="image styled-image" src="img/gebouw.jpg" />
        </div>
      </div>
      <div class="row" style="margin-top: 32px">
        <div class="col-sm">
          <GmapMap
            class="styled-image"
            :center="{ lat: 52.3679, lng: 4.90567 }"
            :zoom="14"
            :options="{
              draggable: false,
              styles: styles,
            }"
            map-type-id="terrain"
          >
            <GmapMarker
              ref="myMarker"
              :position="google && new google.maps.LatLng(52.3679, 4.90567)"
            />
          </GmapMap>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { gmapApi } from 'vue2-google-maps'

export default {
  name: 'contact',
  computed: {
    google: gmapApi,
  },
  data() {
    return {
      styles: [
        {
          featureType: 'all',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#736c68',
            },
          ],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#e7e6e5',
            },
          ],
        },
        {
          featureType: 'landscape.natural',
          elementType: 'all',
          stylers: [
            {
              visibility: 'on',
            },
            {
              color: '#d4e4d3',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'geometry.fill',
          stylers: [
            {
              visibility: 'on',
            },
            {
              color: '#f5f5f5',
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#d4e4d3',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: 'rgba(0, 0, 0, 0.5)',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#e7e6e5',
            },
            {
              gamma: '0.65',
            },
            {
              lightness: '0',
            },
          ],
        },
        {
          featureType: 'transit',
          elementType: 'labels.text',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'all',
          stylers: [
            {
              color: '#0069d9',
            },
          ],
        },
      ],
    }
  },
}
</script>
<style lang="scss">
.vue-map-container {
  width: 100%;
  height: 400px;
}

.screwYou {
  visibility: hidden;
}

.image {
  max-width: 100%;
}
</style>